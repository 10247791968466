<template>
<div>
  <div>
    <div class="content-header container-fluid bg-white">
      <div class="row">
        <div class="col-sm-4">
          <h4><i class="ti-shield"></i> 安全巡检 | 每日报表</h4>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="row">
        <div class="col-md-12">
          <div class="w-block" style="padding: 2px;">
            <el-date-picker v-model="grid.date_range" @change="filter" type="daterange" value-format="yyyy-MM-dd"
                            range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期" style="margin-left: 5px"/>
          </div>
          <wgrid :data="grid.ls" v-loading="grid.loading" :total="grid.total" :page-index="grid.sea.PAGE_INDEX+1" @page-changed="loadPage">
            <el-table-column label="日期" width="100">
              <template slot-scope="scope" align="center">{{scope.row.CREATED_DT | datetime('YYYY-MM-DD')}}</template>
            </el-table-column>
            <el-table-column prop="CNT" label="全部" width="80" align="center"/>
            <el-table-column prop="NOR" label="正常" width="80" align="center"/>
            <el-table-column prop="ERR" label="异常" width="80" align="center"/>
            <el-table-column prop="NUL" label="未检" width="80" align="center"/>
            <el-table-column prop="ER_NAME" label="异常项" width="400" />
            <el-table-column prop="UN_NAME" label="未检项" width="400" />
            <el-table-column />
          </wgrid>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import Wgrid from "@/components/wgrid";
export default {
  name: "index",
  components: {Wgrid},
  data(){
    return{
      grid:{
        sea:{
          PAGE_SIZE: 12,
          PAGE_INDEX: 0,
        },
        ls:[],
        total:0,
        loading:false
      },
      ls_conf:[]
    }
  },
  created() {
    this.getList();
  },
  methods:{

    filter(){
      if (this.grid.date_range!=null && this.grid.date_range.length>1){
        this.grid.sea.DTS=this.grid.date_range[0];
        this.grid.sea.DTE=this.grid.date_range[1];
      }else{
        this.grid.sea.DTS=this.grid.sea.DTE="";
      }
      this.grid.sea.PAGE_INDEX = 0;
      this.grid.ls = [];
      this.getList();
    },
    loadPage(pi){
      this.grid.sea.PAGE_INDEX = pi-1;
      this.getList();
    },
    getList(){
      let self = this;
      this.grid.loading=true;
      this.whale.remote.getCollection({
        url: "/api/School/SAFE/RepApi/GetList",
        data: this.grid.sea,
        completed: function (its, n) {
          self.grid.ls=its;
          self.grid.total=n;
          self.grid.loading=false;
        }
      })
    },
  }
}
</script>

